import React, { useCallback, useState } from "react"
import Layout from "../components/layout"
import Twitter from "../images/twitter-brands.svg"
import Fb from "../images/facebook-f-brands.svg"
import Insta from "../images/instagram-brands.svg"
import Li from "../images/linkedin-in-brands.svg"
import { graphql, useStaticQuery } from "gatsby"
import queryString from "query-string"
import { useNavigate } from "@reach/router"

function createEmptyForm() {
  return {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  }
}

export default () => {
  const {
    file: {
      childMarkdownRemark: { frontmatter },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/contact.md" }) {
        childMarkdownRemark {
          frontmatter {
            title
            meta {
              title
              description
            }
          }
        }
      }
    }
  `)

  const [formValues, setFormValues] = useState(createEmptyForm())
  const [isSubmitting, setSubmitting] = useState(false)
  const navigate = useNavigate()

  const onSubmit = useCallback(
    async e => {
      e.preventDefault()
      setSubmitting(true)

      try {
        const response = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: queryString.stringify({
            "form-name": "contact",
            ...formValues,
          }),
        })
        if (!response.ok) {
          const text = await response.text()
          setSubmitting(false)
          throw new Error(text)
        }
        setFormValues(createEmptyForm())
        await navigate("/contact-success/")
      } catch (e) {
        setSubmitting(false)
        throw e
      }
    },
    [formValues, navigate]
  )

  const onFieldChange = useCallback(
    e => {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      })
    },
    [formValues]
  )

  return (
    <Layout meta={frontmatter.meta}>
      <section className="container lg-pad">
        <div className="row">
          <h1>{frontmatter.title}</h1>
          <div className="grid-2-col">
            <div
              className="contact-page-content"
              itemScope=""
              itemType="http://schema.org/LocalBusiness"
            >
              <p className="lead">
                Complete the form and we'll get back to you within 24 hours.
              </p>
              <h6>Address</h6>
              <address
                itemProp="address"
                itemScope=""
                itemType="http://schema.org/PostalAddress"
              >
                <span itemProp="streetAddress">
                  378 Victoria Parade <br />
                </span>
                <span itemProp="addressLocality">East Melbourne</span>,
                <span itemProp="addressRegion"> VIC 3002</span>,
                <span itemProp="addressCountry"> Australia</span>
              </address>
              <div className="mid-contact-row">
                <h6>Email</h6>
                <a href="mailto:info@creativethinkinginstitute.com">
                  info@creativethinkinginstitute.com
                </a>
              </div>
              <div className="social-icons-contact">
                <h6>Social</h6>
                <a href="https://twitter.com/CTI_Pty_Ltd">
                  <img src={Twitter} alt="Twitter icon" />
                </a>
                <a href="https://www.facebook.com/Creative-Thinking-Institute-100202631717583/">
                  <img src={Fb} alt="Facebook icon" />
                </a>
                <a href="https://www.instagram.com/creativethinkinginstitute/">
                  <img src={Insta} alt="Instagram icon" />
                </a>
                <a href="http://linkedin.com/company/creative-thinking-institute">
                  <img src={Li} alt="Linkedin icon" />
                </a>
              </div>
            </div>
            <div>
              <form
                className="contact-form"
                name="contact"
                id="contact"
                method="POST"
                data-netlify="true"
                onSubmit={onSubmit}
              >
                <div className="form-item">
                  <label htmlFor="nameField">Name</label>
                  <input
                    id="nameField"
                    type="text"
                    name="name"
                    value={formValues.name}
                    onChange={onFieldChange}
                    aria-label="Name"
                    required
                    disabled={isSubmitting}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="emailField">Email</label>
                  <input
                    id="emailField"
                    type="email"
                    name="email"
                    aria-label="Email"
                    required
                    value={formValues.email}
                    onChange={onFieldChange}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="phoneNumberField">Phone number</label>
                  <input
                    id="phoneNumberField"
                    type="tel"
                    name="phoneNumber"
                    required
                    aria-label="Phone number"
                    value={formValues.phoneNumber}
                    onChange={onFieldChange}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="messageField">How can we help?</label>
                  <textarea
                    id="messageField"
                    name="message"
                    rows="5"
                    aria-label="Message"
                    value={formValues.message}
                    onChange={onFieldChange}
                    disabled={isSubmitting}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    value="Send"
                    className="btn btn-green-solid"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
